<!-- #################################################################################### -->
<!-- ###### HERINCOHAN                                                             ###### -->
<!-- ###### @author: Estefania Villada Chavarria                                   ###### -->
<!-- ###### @date: Agosto 2024                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <div class="contenido">
    <section class="d-flex">
      <!-- Filtrar por código -->
      <v-text-field class="me-2 filtro" v-model="buscar.codigo" label="Código" outlined dense hide-details
        color="primary">
      </v-text-field>

      <!-- Filtrar por nombre -->
      <v-text-field class="me-2 filtro" v-model="buscar.nombre" label="Nombre" outlined dense
        hide-details color="primary">
      </v-text-field>

      <!-- Filtrar por estado -->
      <v-autocomplete class="filtro" v-model="buscar.estado" label="Estado" outlined dense
        hide-details color="primary" :items="estados" :menu-props="{ offsetY: true }">
      </v-autocomplete>

      <!-- Este botón abre un diálogo para agregar una nueva aseguradora -->
      <div class="ms-2" v-if="enterprise.code !== 1">
        <v-tooltip left color="success" :open-on-focus="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn fab small color="success" v-on="on" v-bind="attrs"
              @mousedown.prevent="dialogoCargoCompetencia = true">
              <v-icon>add</v-icon>
            </v-btn>
          </template>
          <span>Agregar</span>
        </v-tooltip>
      </div>
    </section>

    <!-- cards que contienen la información de los cargos con sus competencias -->
    <v-container class="contenedor-cards  ma-0 pa-0 pt-4">
      <v-row class="ma-0">
        <v-col v-if="cardCargos.items.length > 0" class="pa-0">
          <v-card class="mb-1 grey lighten-2">
            <v-card-text class="font-weight-black d-flex align-center black--text">
              <v-row class="pe-4">
                <v-col cols="12" md="2" class="d-flex justify-center pa-0">CARGO</v-col>
                <v-col cols="12" md="8" class="d-flex justify-center pa-0">COMPETENCIA</v-col>
                <v-col cols="12" md="2" class="d-flex justify-center pa-0">ACCIONES</v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col v-if="cardCargos.items.length > 0" cols="12" md="12" class="pa-0 contenido-cards">
          <v-card border class="mb-1 elevation-2" v-for="item in cardCargos.items" :key="item.idCargo">
            <v-card-text style="color:black">
              <v-row>
                <!-- Primer columna con la información general -->
                <v-col cols="12" md="2" class="d-flex align-center">
                  <v-row class="ps-2">
                    <v-col cols="12" md="12" class="pa-1">
                      <span class="info-card">Código: </span> {{ item.codStone }}
                    </v-col>
                    <v-col cols="12" md="12" class="pa-1">
                      <span class="info-card">Nombre: </span>{{ item.nombre }}
                    </v-col>
                    <v-col cols="12" md="12" class="pa-1">
                      <span class="info-card">Estado: </span>
                      <span class="font-weight-black"
                        :class="item.eliminado === false ? 'success--text' : 'error--text'">
                        {{ item.eliminado === false ? 'Activo' : 'Inactivo' }}
                      </span>
                    </v-col>
                  </v-row>
                </v-col>

                <!-- Segunda columna con la descripción -->
                <v-col cols="12" md="8" class="d-flex align-center">
                  <v-row class="pe-2">
                    <v-col cols="12" md="12" class="pa-0">
                      <div v-if="item.competenciasDescriptores.length > 0">
                        <v-simple-table dense class="elevation">
                          <thead>
                            <tr>
                              <th class="text-center" width="20%">COMPETENCIA</th>
                              <th class="text-center" width="10%">NIVEL</th>
                              <th class="text-center" width="70%">DESCRIPCIÓN</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="competencia in item.competenciasDescriptores" :key="competencia.idCompetencia">
                              <td>{{ competencia.competencia.nombre }}</td>
                              <td class="text-center">{{ competencia.nivel }}</td>
                              <td>{{ competencia.descripcion }}</td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                      </div>
                      <div v-else class="text-center">
                        No existen competencias relacionadas a este cargo.
                      </div>
                    </v-col>
                  </v-row>
                </v-col>

                <!-- Tercera columna de las acciones -->
                <v-col cols="12" md="2" class="d-flex align-center" align="center">
                  <v-row class="pe-2">
                    <v-col cols="12" md="12" class="pa-0">
                      <v-tooltip bottom :color="item.eliminado === false ? 'orange' : 'grey'">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon fab small v-on="on" v-bind="attrs" class="ms-2 me-2"
                            :color="item.eliminado === false ? 'orange' : 'grey'">
                            <v-icon @click="item.eliminado === false && abrirDialogoEditar(item)">border_color</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ item.eliminado === false ? 'Editar' : 'Cargo inactivo' }}</span>
                      </v-tooltip>

                      <v-tooltip bottom :color="item.eliminado === false ? 'error' : 'success'">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn class="ms-2 me-2" v-on="on" v-bind="attrs" fab icon small
                            :color="item.eliminado === false ? 'error' : 'success'">
                            <v-icon @click="abrirDialogoEstado(item)">{{ item.eliminado === false ? 'person_add_disabled' : 'how_to_reg' }}</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ item.eliminado === false ? 'Inactivar' : 'Activar' }}</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col v-else cols="12" md="12" class="mt-4 pa-0 d-flex justify-center">
          <span>No se encontraron resultados.</span>
        </v-col>
      </v-row>

      <!-- Paginado -->
      <v-row v-if="cardCargos.items.length > 0" class="paginado">
        <span class="me-6">Items por página:</span>
        <div>
          <v-select class="me-6 select" hide-details v-model="cardCargos.itemsPerPage" dense
            :items="cardCargos.opciones">
          </v-select>
        </div>

        <span class="items"> {{ rangoItems }} de {{ cardCargos.totalElements }}</span>
        <v-btn icon fab small :disabled="cardCargos.page === 1"
          @click="cardCargos.page = 1">
          <v-icon medium>first_page</v-icon>
        </v-btn>
        <v-btn icon fab small :disabled="cardCargos.page === 1"
          @click="cardCargos.page = cardCargos.page - 1">
          <v-icon medium>chevron_left</v-icon>
        </v-btn>
        {{ cardCargos.page }}
        <v-btn icon fab small :disabled="cardCargos.page === cardCargos.totalPage"
          @click="cardCargos.page = cardCargos.page + 1">
          <v-icon medium>chevron_right</v-icon>
        </v-btn>
        <v-btn icon fab small :disabled="cardCargos.page === cardCargos.totalPage"
          @click="cardCargos.page = cardCargos.totalPage">
          <v-icon medium>last_page</v-icon>
        </v-btn>
      </v-row>
    </v-container>

    <v-dialog v-model="dialogoCargoCompetencia" persistent transition="dialog-bottom-transition" max-width="38rem"
      :editar="false">
      <v-form ref="formGrupoProceso">
        <v-card>
          <v-card-title class="encabezado">{{ tituloDialogo }}</v-card-title>
          <v-card-text class="pt-6 contenido-card">
            <div v-if="editar">
              <v-row class="ms-1" v-for="(competenciaDescriptor, index) in crearCargoCompetencia.competenciasDescriptores" :key="index">
                <v-col cols="11" class="pa-0">
                  <div class="d-flex mt-4">
                    <v-autocomplete class="me-2 competencia" label="Competencia" v-model="competenciaDescriptor.competencia.nombre" color="primary"
                      :items="mostrarOpciones(index)" dense outlined :rules="index === 0 ? rules.required : []" item-text="nombre"
                      :menu-props="{ offsetY: true, maxHeight: 200 }" @change="seleccionarCompetencia(index)">
                    </v-autocomplete>
                    <v-autocomplete class="nivel" v-model="competenciaDescriptor.nivel" :items="niveles" label="Nivel" outlined
                      dense color="primary" @change="seleccionarCompetencia(index)" :rules="index === 0 ? rules.required : []">
                    </v-autocomplete>
                  </div>
                  <v-textarea class="mb-3" :rows="1" label="Descripción" auto-grow row-height="15" multi-line v-model="competenciaDescriptor.descripcion"
                    dense outlined color="primary" hide-details readonly>
                  </v-textarea>
                </v-col>
                <v-col cols="1" class="pa-0 d-flex justify-center align-center">
                  <v-btn v-if="index > 0" color="error" icon fab small @click="eliminarCompetencia(index)">
                    <v-icon>remove</v-icon>
                  </v-btn>
                  <v-btn v-else color="success" icon fab small @click="agregarCompetencia" :disabled="!competenciasLlenas">
                    <v-icon>add</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <div class="d-flex" v-else>
              <v-text-field class="me-2" v-model="crearCargo.codigo" outlined dense label="Código" :rules="rules.required" @paste.prevent
                @keypress="validarNumeros" @input="validarExistencia()"  :error-messages="validarCargo" style="width: 30%;">
              </v-text-field>
              <v-text-field v-model="crearCargo.nombre" outlined dense color="primary" label="Nombre" :rules="rules.required" style="width: 70%;">
              </v-text-field>
            </div>
            <div class="d-flex justify-end mt-2">
              <v-btn class="me-2" text color="error" @click="clear()">Cerrar</v-btn>
              <v-btn color="success"
                :disabled="!editar ? !formValid : !competenciasLlenas" depressed
                @click="!editar ? agregarCargo() : editarCargoCompetencia()"> Guardar
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>

    <!-- dialogo para cambiar el estado -->
    <v-dialog v-model="dialogoCambiarEstado" transition="dialog-bottom-transition" max-width="23rem" persistent>
      <v-card>
        <v-card-title class="encabezado">
          <span class="text-h6"> {{ mensajeEstado }} </span>
        </v-card-title>
        <v-card-text class="pt-5">
          <div class="d-flex justify-end">
            <v-btn class="me-2" color="error" text @click="dialogoCambiarEstado = false">No</v-btn>
            <v-btn color="success" depressed @click="cambiarEstado()">Si</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<!-- #################################################################################### -->
<!-- ###### Sección de Script                                                      ###### -->
<!-- #################################################################################### -->
<script>
import { mapState } from "vuex";
import { Role } from "../../../../router/role";
import { required } from "@/validators/validations";

export default {
  name: "Cargos",
  data() {
    return {
      buscar: {
        nombre: '',
        codigo: '',
        estado: null,
      },
      estados: [
        { text: 'Activo', value: false },
        { text: 'Inactivo', value: true }
      ],
      crearCargoCompetencia: {
        competenciasDescriptores: [
          {
            competencia:null,
            nivel: null,
            descripcion: null,
            idCompetencia: null
          }
        ]
      },
      crearCargo: {
        codigo: null,
        nombre: '',
      },
      dialogoCargoCompetencia: false,
      cardCargos: {
        items: [],
        opciones: [10, 20, 30],
        itemsPerPage: 10,
        page: 1,
        totalPage: 0,
      },
      cargoSeleccionado: {},
      usuario: '',
      userRoles: {},
      roles: Role,
      rules: {
        required: [required],
      },
      tituloDialogo: 'Agregar cargo',
      dialogoCambiarEstado: false,
      mensajeEstado: '',
      niveles: [1,2,3,4],
      competencias: [],
      descripcionCompetencias: [],
      editar: false,
      validarCargo: '',
    }
  },
  mounted() {
    this.listarCargos();
    this.usuario = this.auth.username.toUpperCase();
  },
  created() {
    this.userRoles = this.auth.roles;
  },
  computed: {
    ...mapState(["enterprise", "auth"]),
    rangoItems() {
      const start = (this.cardCargos.page - 1) * this.cardCargos.itemsPerPage + 1;
      let end = this.cardCargos.page * this.cardCargos.itemsPerPage;
      if (end > this.cardCargos.totalElements) {
        end = this.cardCargos.totalElements;
      }
      return `${start} - ${end}`;
    },
    competenciasLlenas() {
      if (!this.crearCargoCompetencia || !this.crearCargoCompetencia.competenciasDescriptores) {
        return false;
      }
      return this.crearCargoCompetencia.competenciasDescriptores.every( competencia => {
        return (competencia.competencia.nombre &&  competencia.nivel && competencia.descripcion)
      });
    },
    competenciasFiltradas() {
      // Si no hay datos iniciales, devolver una lista vacía
      if (!this.competencias || !this.crearCargoCompetencia) {
        return [];
      }

      // Filtrar competencias que ya están seleccionadas
      return this.competencias.filter(comp => {
        return !this.crearCargoCompetencia.competenciasDescriptores.some(descriptor => {
          return descriptor.idCompetencia === comp.idCompetencia;
        });
      });
    },
    formValid() {
      return this.crearCargo.codigo && this.crearCargo.nombre && this.validarCargo === "";
    }
  },
  watch: {
    'cardCargos.page': function () {
      this.listarCargos();
    },
    'cardCargos.itemsPerPage': function () {
      this.cardCargos.page = 1;
      this.listarCargos();
    },
    'buscar.codigo': function () {
      this.cardCargos.page = 1;
      this.listarCargos();
    },
    'buscar.nombre': function () {
      this.cardCargos.page = 1;
      this.listarCargos();
    },
    'buscar.estado': function () {
      this.cardCargos.page = 1;
      this.listarCargos();
    },
    'dialogoCargoCompetencia': function (nextValue) {
      if (nextValue) return this.listarCompetencias();
    }
  },
  methods: {
    mostrarOpciones(index) {
      const competenciaDescriptor = this.crearCargoCompetencia.competenciasDescriptores[index];
      // Siempre incluye la competencia actualmente seleccionada en la lista
      this.seleccionarCompetencia(index);
      console.log(competenciaDescriptor)
      if (competenciaDescriptor.idCompetencia) {
        const seleccionada = this.competencias.find(
          comp => comp.idCompetencia === competenciaDescriptor.idCompetencia
        );
        console.log(seleccionada)
        console.log('d')
        return seleccionada
          ? [seleccionada, ...this.competenciasFiltradas]
          : this.competenciasFiltradas;
      }
      return this.competenciasFiltradas;
    },
    listarCargos() {
      this.$http.get(`msa-hr/api/cargo/listar`, {
        params: {
          page: this.cardCargos.page - 1,
          size: this.cardCargos.itemsPerPage,
          nombre: this.buscar.nombre,
          codStone: this.buscar.codigo,
          idEmpresa: this.enterprise.code,
          eliminado: this.buscar.estado,
        }
      }).then((response) => {
        
        this.cardCargos.items = response.data.content;
        this.cardCargos.totalPage = response.data.totalPages;
        this.cardCargos.totalElements = response.data.totalElements;
      }).catch((error) => {
        console.error(error);
      })
    },
    listarCompetencias() {
      this.$http.get(`msa-hr/api/competencia/listar`, {
        params: {
          page: 0,
          size: 200,
          idEmpresa: this.enterprise.code,
          eliminado: false,
          tipoCompetenciaValue: 'I',
        }
      }).then((response) => {
        this.competencias = response.data.content;
        // this.descripcionCompetencias = response.data.content;
      }).catch((error) => {
        console.error(error);
      })

    },
    agregarCompetencia() {
      this.crearCargoCompetencia.competenciasDescriptores.push({ 
        nivel: null,
        descripcion: null,
        competencia:{nombre: null},
        idCompetencia: null
      });
    },
    eliminarCompetencia(index) {
      this.crearCargoCompetencia.competenciasDescriptores.splice(index, 1);
    },
    seleccionarCompetencia(index) {

      const competenciaDescriptor = this.crearCargoCompetencia.competenciasDescriptores[index];

      if (!competenciaDescriptor || !competenciaDescriptor.competencia.nombre || !competenciaDescriptor.nivel) {
        // Si faltan datos, limpiamos la descripción y salimos.
        competenciaDescriptor.descripcion = '';
        competenciaDescriptor.idCompetencia = null;
        return;
      }

      // Buscar la competencia seleccionada por nombre.
      const competenciaSeleccionada = this.competencias.find(comp => 
        comp.nombre === competenciaDescriptor.competencia.nombre
      );
      
      if (competenciaSeleccionada) {
        // Actualiza el id de la competencia
        competenciaDescriptor.idCompetencia = competenciaSeleccionada.idCompetencia;

        // Buscar el descriptor correspondiente al nivel.
        const descriptorSeleccionado = competenciaSeleccionada.competenciaDescriptor.find(
          desc => desc.nivel === competenciaDescriptor.nivel
        );

        competenciaDescriptor.descripcion = descriptorSeleccionado
        ? descriptorSeleccionado.descripcion
        : ''; // Si no hay descriptor para el nivel, limpiar descripción.
      } else {
        // Si no se encuentra la competencia, limpiar campos.
        competenciaDescriptor.descripcion = '';
        competenciaDescriptor.idCompetencia = null;
      }

    },
    abrirDialogoEditar(item) {
      this.editar = true;
      this.tituloDialogo = `Editar ${item.nombre}`;
      this.cargoSeleccionado = item;

      this.crearCargoCompetencia = {
        competenciasDescriptores: JSON.parse(JSON.stringify(item.competenciasDescriptores))
      };

      if (!this.crearCargoCompetencia.competenciasDescriptores || this.crearCargoCompetencia.competenciasDescriptores.length === 0) {
        this.crearCargoCompetencia = {
          competenciasDescriptores: [
            {
              nivel: null,
              descripcion: null,
              competencia: {nombre:null},
              idCompetencia: null
            }
          ]
        }
      }
      this.dialogoCargoCompetencia = true;
    },
    agregarCargo(){
      const cargoCompetencia = {
        idEmpresa: this.enterprise.code,
        codStone: this.crearCargo.codigo,
        nombre: this.crearCargo.nombre,
        eliminado: false,
      }
      this.$http.post(`msa-hr/api/cargo/guardar`, cargoCompetencia)
        .then(() => {
          this.listarCargos();
          this.dialogoCargoCompetencia = false;
          this.clear();
        }).catch((error) => {
          console.error(error);
        })
    },
    editarCargoCompetencia() {

      const competenciasDescriptores = [];
      
      this.crearCargoCompetencia.competenciasDescriptores.forEach(descriptor =>
      competenciasDescriptores.push({
        idCompetencia: descriptor.idCompetencia,
        nivel: descriptor.nivel
      })
      )

      const cargoCompetencia = {
        idEmpresa: this.enterprise.code,
        codStone: this.cargoSeleccionado.codStone,
        nombre: this.cargoSeleccionado.nombre,
        eliminado: false,
        competenciasDescriptores: competenciasDescriptores,
      }

      this.$http.post(`msa-hr/api/cargo/guardar`, cargoCompetencia)
        .then(() => {
          this.listarCargos();
          this.dialogoCargoCompetencia = false;
          this.clear();
        }).catch((error) => {
          console.error(error);
        })
    },
    abrirDialogoEstado(item) {
      this.dialogoCambiarEstado = true;
      this.cargoSeleccionado = item;
      this.mensajeEstado = this.cargoSeleccionado.eliminado === false ? '¿Desea inactivar el cargo?' : '¿Desea activar el cargo?';
    },
    cambiarEstado() {
      const estado = this.cargoSeleccionado.eliminado === false ? true : false;
      const cambiarEstado = {
        codStone: this.cargoSeleccionado.codStone,
        idEmpresa: this.enterprise.code,
        nombre: this.cargoSeleccionado.nombre,
        competenciasDescriptores: this.cargoSeleccionado.competenciasDescriptores.map(descriptor => ({
          idCompetencia: descriptor.competencia.idCompetencia,
          nivel: descriptor.nivel
        })),
        fechaStone: this.cargoSeleccionado.fechaStone,
        eliminado: estado,
        lastmodifiedby: this.usuario,
      }
      this.$http.post(`msa-hr/api/cargo/guardar`, cambiarEstado)
        .then(() => {
          this.listarCargos();
          this.dialogoCompetencia = false;
          this.clear();
        }).catch((error) => {
          console.error(error);
        });
    },
    validarNumeros(event) {
      const key = event.key;
      if (!((key >= '0' && key <= '9'))) {
        event.preventDefault();
      }
    },
    validarExistencia() {
      if (this.crearCargo.codigo) {
        this.$http.get(`msa-hr/api/cargo/listar`, {
          params: {
            codStone: this.crearCargo.codigo,
            idEmpresa: this.enterprise.code
          }
        }).then((response) => {

          const respuesta = response.data.content;
          if (respuesta[0] && respuesta[0].codStone === this.crearCargo.codigo) {
            this.validarCargo = "El cargo ya existe.";
          } else {
            this.validarCargo = "";
          }
        })
      }
    },
    clear() {
      this.dialogoCargoCompetencia = false;
      this.dialogoCambiarEstado = false;
      this.editar = false;
      this.tituloDialogo = "Agregar cargo";
      this.cargoSeleccionado = {};
      this.crearCargoCompetencia = {
        competenciasDescriptores: [
          {
            nivel: null,
            descripcion: null,
            nombre: null,
            idCompetencia: null
          }
        ]
      };
      this.crearCargo.nombre = '';
      this.crearCargo.codigo = null;
      this.validarCargo = '';
      if (this.$refs.formGrupoProceso) {
        this.$refs.formGrupoProceso.reset();
      }
    }
  },
}
</script>
<!-- #################################################################################### -->
<!-- ###### Sección de Style                                                       ###### -->
<!-- #################################################################################### -->
<style scoped>
.contenido {
  padding: 1rem;
  width: 100%;
  height: 85vh;
}

.encabezado {
  background-color: #1867c0;
  color: white;
}

.contenedor-cards {
  width: 100%;
  max-width: none;
}

.contenido-cards {
  max-height: 60vh;
  overflow-y: auto;
  border: solid 0.5px;
  border-color: #e0e0e0;
}

.info-card {
  color: black;
  padding: 3px;
  font-weight: bold;
}

.contenedor-card div {
  color: black;
}

.paginado {
  font-size: small;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  padding-top: 12px;
}

.paginado button {
  border: none;
  margin: 0 7px;
}

::v-deep .elevation div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
  font-weight: bold;
  color: black !important;
  height: 28px;
}

::v-deep .elevation div table tbody tr td {
  height: 25px;
}

.elevation {
  border: solid rgb(181, 178, 178) 0.5px;
  width: 100%;
}

.filtro {
  width: 33.33%;
}

.competencia {
  width: 70%;
}

.nivel {
  width: 30%
}

.contenido-card {
  overflow: auto;
  max-height: 80vh;
}

::v-deep .select .v-input__control {
  width: 55px !important;
}
</style>